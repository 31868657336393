<div class="content" role="main">
  <div class="header-container" [className]="this.existing === 'no' ? 'new-customer' : 'existing-customer'">
    <div class="logo-wrapper">
      <img src="assets/images/wed2b-logo.svg" width="150">
      <h6 class="logo-title" i18n>The Bridal Experts</h6>
    </div>
    <div class="card-container">
      <div *ngFor="let language of languages">
        <a  class="card" [ngClass]="(this.locale == language.locale)?'active':''" rel="noopener" href="{{language.href}}">
          <img width="40" alt="{{language.name}}" src="assets/images/flags/{{language.image}}"/>
          <span>{{language.name}}</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </a>
      </div>
    </div>
    <div class="intro-container">
      <h1 i18n>Welcome, have you visited us before?</h1>
      <nav>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="yesNo">
          <mat-radio-button [disabled]="!ipSuccess" id="yes" value="yes" (click)="toggleForm('yes', true)"><span i18n>Yes</span></mat-radio-button>
          <mat-radio-button [disabled]="!ipSuccess" id="no" value="no" (click)="toggleForm('no', false)"><span i18n>No</span></mat-radio-button>
        </mat-radio-group>
      </nav>
    </div>
  </div>
  <div class="form-container" [ngClass]="this.existing !== null ? 'active' : ''">
    <app-progress-spinner></app-progress-spinner>
    <app-new-customer *ngIf="showNewCustomer" (existing)="back($event)" (submissionComplete)="completed($event)"></app-new-customer>
    <app-existing-customer *ngIf="showExistingCustomer" (submissionComplete)="completed($event)" (toggleForm)="toggleForm('no', false)"></app-existing-customer>
  </div>
  <app-thank-you *ngIf="submissionComplete" (submissionComplete)="completed($event)"></app-thank-you>
  <!-- Footer -->
  <footer></footer>
</div>
<div class="connected-store-info">&#64; {{this.year}} WED2B Limited - {{storeName}}</div>
<router-outlet></router-outlet>
