import {Component, EventEmitter, OnInit, Output, ElementRef} from '@angular/core';
import {FormControl, UntypedFormBuilder, Validators, FormArray, UntypedFormGroup} from '@angular/forms';
import {AppConfigService} from '../app-config.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { Customer } from '../customer';

@Component({
  selector: 'app-existing-customer',
  templateUrl: './existing-customer.component.html',
  styleUrls: ['./existing-customer.component.less']
})
export class ExistingCustomerComponent implements OnInit {
  @Output() submissionComplete = new EventEmitter();
  @Output() toggleForm = new EventEmitter();
  submitButtonText = $localize`Submit`;
  error: boolean | false | undefined;
  errorMessage: string | undefined;
  formSubmitted = false;
  count = 0;
  public customer: Customer | undefined;
  existingCustomer: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private srv: AppConfigService, private elementRef: ElementRef) {
    this.existingCustomer = this.fb.group({
      email: ['', Validators.required],
      visitReason: this.fb.group({
        reason: ['', Validators.required]
      }),
      entourage: ['', Validators.required]
    });
  }

  ngOnInit(): void {}

  // tslint:disable-next-line:typedef
  increment() {
    if (this.count < 20) {
      this.count++;
      this.existingCustomer.get('entourage')?.setValue(this.count);
    }
  }

  // tslint:disable-next-line:typedef
  decrement() {
    if (this.count > 1) {
      this.count--;
      this.existingCustomer.get('entourage')?.setValue(this.count);
    }
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    // TODO: Use EventEmitter with form value
    const formData = this.existingCustomer.value;
    const ip: any = localStorage.getItem('wf_ip');
    // tslint:disable-next-line:max-line-length
    this.customer = {email: formData.email, visitReason: formData.visitReason.reason, entourage: formData.entourage.toString(), storeIp: ip};
    this.submitButtonText = $localize`Fetching...`;
    this.formSubmitted = true;
    this.srv.createUpdateCustomer(this.customer).subscribe(data => {
      this.submitButtonText = $localize`Complete`;
      this.formSubmitted = true;
      this.submissionComplete.emit(true);
    }, err  => {
      this.error = true;
      if (err) {
        if (err.status === 404) {
          console.log(err);
          this.errorMessage = (err.error) ? err.error.error.message : err.message;
          this.submitButtonText = $localize`Submit`;
          this.formSubmitted = true;
        } else if (err.status === 400 && err.error.error.code === 'Application_DialogException') {
          this.errorMessage = $localize`I’m sorry we could not find you, please re-enter your details`;
          this.srv.setObjData(this.existingCustomer.value);
          setTimeout(() => {
            this.toggleForm.emit();
          }, 5000);
        } else {
          console.log(err);
          this.errorMessage = (err.error) ? err.error.error.message : err.message;
        }
      }
      this.submitButtonText = $localize`Submit`;
      this.formSubmitted = true;
    }, () => {
      this.submitButtonText = $localize`Submit`;
      this.formSubmitted = true;
    });
  }
}
