export class Customer {
  visitReason?: string;
  fname?: string;
  lname?: string;
  weddingDate?: string;
  entourage?: string;
  email!: string;
  postcode?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  town?: string;
  county?: string;
  country?: string;
  countryCode?: string;
  telephone?: string;
  sms?: string;
  phone?: string;
  cmail?: string;
  dropDownHeardFrom?: string;
  comment?: string;
  storeIp?: string;
  customerSize?: string;
}
