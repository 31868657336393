import {Component, OnInit, EventEmitter, LOCALE_ID, Inject, ElementRef, Input, Output} from '@angular/core';
import {FormControl, UntypedFormBuilder, Validators, FormArray, UntypedFormGroup} from '@angular/forms';
import {AppConfigService} from '../app-config.service';
import {MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Customer } from '../customer';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.less'],
})
export class NewCustomerComponent implements OnInit {
  @Output() submissionComplete = new EventEmitter();
  @Output() existing = new EventEmitter();
  submitButtonText = $localize`Submit`;
  error: boolean | false | undefined;
  errorMessage: string | undefined;
  formSubmitted = false;
  allMarketingPermissionsComplete = false;
  countryList: any = [];
  addressList: any = [];
  selectedAddress: any;
  enterAddressManually = false;
  showEnterAddressManually = false;
  readonly: boolean = true;
  locale: any;
  count = 0;
  countryIpLocation: 'gb' | undefined;
  hideAddressList: boolean | false | undefined;
  referrals: any = [
    // {name: $localize`---Please Select---`, id: null, disable: false},
    {name: $localize`Facebook`, id: 1, disable: false},
    {name: $localize`Instagram`, id: 2, disable: false},
    {name: $localize`Recommendation`, id: 3, disable: false},
    {name: $localize`Google Search`, id: 4, disable: false},
    {name: $localize`ThePerfectWedding.nl`, id: 11, disable: true},
    {name: $localize`Pinterest`, id: 6, disable: false},
    {name: $localize`YouTube`, id: 7, disable: false},
    {name: $localize`TikTok`, id: 12, disable: false},
    {name: $localize`Spotify`, id: 8, disable: false},
    {name: $localize`Radio`, id: 9, disable: false},
    {name: $localize`Other`, id: 5, disable: false},
    {name: $localize`TV`, id: 10, disable: false}
  ];
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedKingdom, CountryISO.Ireland,
    CountryISO.Netherlands, CountryISO.Germany,
    CountryISO.Belgium
  ];
  telephoneCountrySelected = CountryISO.Germany;
  newCustomer: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private srv: AppConfigService,
    @Inject(LOCALE_ID) protected localeId: string,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    elementRef: ElementRef) {
    this.locale = localeId;
    this.countryIpLocation = 'gb';
    this.newCustomer = this.fb.group({
      date: [this.todayDate(), Validators.required],
      referralId: [null, [Validators.required]],
      comments: [''],
      visitReason: this.fb.group({
        reason: [null, Validators.required]
      }),
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      weddingDate: [''],
      email: ['', Validators.required],
      entourage: ['', Validators.required],
      telephone: [],
      address: this.fb.group({
        countryCode: ['', Validators.required],
        query: [''],
        postcode: ['', Validators.required],
        lineAddress1: ['', Validators.required],
        lineAddress2: [''],
        lineAddress3: [''],
        town: [''],
        county: ['']
      }),
      marketingPermissions: this.fb.group({
        sms: [false],
        phone: [false],
        email: [false],
        allowAll: [false],
      }),
      acceptTerms: [false]
    });
  }

  ngOnInit(): void {
    if (this.locale === 'nl') {
      // @ts-ignore
      const  obj = this.referrals.find((x: { id: string; }) => x.id === 11);
      const index = this.referrals.indexOf(obj);
      this.referrals[index].disable = false;
    }
    if (this.countryList.length === 0) {
      this.srv.getCountryList()
        .subscribe(countryList => {
          this.countryList = countryList.countries;
        });
      if (this.locale === 'en-US' || this.locale === 'en-GB' || this.locale === 'gb') {
        this.countryIpLocation = 'gb';
      }else {
        this.countryIpLocation = this.locale;
      }
    }
    if (this.srv.getObjData() !== undefined) {
      const obj = this.srv.getObjData();
      // @ts-ignore
      this.newCustomer.get('email')?.setValue(obj.email);
      // @ts-ignore
      this.newCustomer.get('entourage')?.setValue(obj.entourage);
      // @ts-ignore
      this.newCustomer.get('visitReason.reason')?.setValue(obj.visitReason.reason);
    }
    this.newCustomer.get('address.countryCode')?.setValue(this.countryIpLocation);

    this.setCountryIso(this.countryIpLocation);
  }

  // tslint:disable-next-line:typedef
  onKey(event: any) {
    if ( event.target.value.length >= 1 ) {
      this.hideAddressList = false;
      this.srv.findAddress(this.newCustomer.get('address.countryCode')?.value, event.target.value).subscribe(addressList => {
        this.addressList = addressList.results;
        this.showEnterAddressManually = false;
        this.enterAddressManually = false;
      }, error1 => {
        if (error1.error) {
          console.log('an error accurred');
          this.showEnterAddressManually = true;
        }
      });
    }else {
      this.addressList = [];
    }
  }

  // tslint:disable-next-line:typedef
  manuallyEnterAddress() {
    console.log('enter');
    this.enterAddressManually = ! this.enterAddressManually;
    this.readonly = false;
    if (!this.enterAddressManually) {
      this.showEnterAddressManually = false;
      this.readonly = true;
    }
  }
  // tslint:disable-next-line:typedef
  onBlur(event: any) {
    this.hideAddressList = true;
  }

  // tslint:disable-next-line:typedef
  onFocus(event: any) {
    this.hideAddressList = false;
  }
  // tslint:disable-next-line:typedef
  increment() {
    if (this.count < 20) {
      this.count++;
      this.newCustomer.get('entourage')?.setValue(this.count);
    }
  }

  // tslint:disable-next-line:typedef
  decrement() {
    if (this.count > 1) {
      this.count--;
      this.newCustomer.get('entourage')?.setValue(this.count);
    }
  }

  // tslint:disable-next-line:typedef
  onCountryChange(iso: any) {
    this.setCountryIso(iso);
  }

  // tslint:disable-next-line:typedef
  setCountryIso(iso: 'gb' | undefined) {
    for (const key in CountryISO) {
      if (CountryISO.hasOwnProperty(key)) {
        // @ts-ignore
        if (CountryISO[key] === iso) {
          // @ts-ignore
          this.telephoneCountrySelected = CountryISO[key];
        }
      }
    }
  }

  onSelect(obj: any): void {
    if (obj.count === 1) {
      this.srv.retrieveAddress(this.newCustomer.get('address.countryCode')?.value, obj.id).subscribe(selectedAddress => {
        this.selectedAddress = selectedAddress.result;
        this.newCustomer.get('address.lineAddress1')?.setValue(this.selectedAddress.line_1);
        this.newCustomer.get('address.lineAddress2')?.setValue(this.selectedAddress.line_2);
        this.newCustomer.get('address.town')?.setValue(this.selectedAddress.locality);
        this.newCustomer.get('address.postcode')?.setValue(this.selectedAddress.postal_code);
        this.newCustomer.get('address.county')?.setValue(this.selectedAddress.province_name);
        this.hideAddressList = true;
        this.selectedAddress = [];
      });
    }else {
      if (this.newCustomer.get('address.query')?.value) {
        this.srv.findAddress(this.newCustomer.get('address.countryCode')?.value,
          this.newCustomer.get('address.query')?.value,
          obj.id).subscribe(addressList => {
          this.addressList = addressList.results;
        });
      } else {
        this.addressList = [];
      }
    }
  }

  // tslint:disable-next-line:typedef
  openDialog(): void {
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      width: '640px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // tslint:disable-next-line:typedef
  todayDate() {
    const today: Date = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const min = today.getMinutes();
    return  yyyy + '-' + mm + '-' + dd;
  }

  // tslint:disable-next-line:typedef
  minDate() {
    return  new Date();
  }

  // tslint:disable-next-line:typedef
  back() {
    this.existing.emit(null);
    this.srv.setObjData(undefined);
  }

  // tslint:disable-next-line:typedef
  updateAllComplete() {
    // @ts-ignore
    // tslint:disable-next-line:no-unused-expression
    this.allMarketingPermissionsComplete =
      this.newCustomer.get('marketingPermissions.sms')?.value === true &&
      this.newCustomer.get('marketingPermissions.phone')?.value === true &&
      this.newCustomer.get('marketingPermissions.email')?.value === true;
    this.newCustomer.get('marketingPermissions.allowAll')?.setValue(this.allMarketingPermissionsComplete);
  }

  // tslint:disable-next-line:typedef
  setAll(completed: boolean) {
    this.allMarketingPermissionsComplete = completed;
    this.newCustomer.get('marketingPermissions.sms')?.setValue(completed);
    this.newCustomer.get('marketingPermissions.phone')?.setValue(completed);
    this.newCustomer.get('marketingPermissions.email')?.setValue(completed);
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.submitButtonText = $localize`Fetching...`;
    this.formSubmitted = true;
    const payload = this.formatPayload(this.newCustomer.value);
    console.log(payload);
    const response  = this.srv.createUpdateCustomer(payload).subscribe(data => {
      this.submitButtonText = $localize`Complete`;
      this.formSubmitted = true;
      this.submissionComplete.emit(true);
    }, err  => {
      this.error = true;
      if (err.status === 404) {
        this.errorMessage = err.error.error.message;
        this.submitButtonText = $localize`Submit`;
        this.formSubmitted = true;
      } else if (err.status === 400 && err.error.error.code === 'Application_DialogException') {
        this.errorMessage = $localize`I’m sorry we could not find you, please re-enter your details`;
        this.srv.setObjData(this.newCustomer.value);
      } else {
        this.errorMessage = err.error.error.message;
        this.formSubmitted = true;
        this.submitButtonText = $localize`Submit`;
      }
      this.submitButtonText = $localize`Submit`;
      this.formSubmitted = false;
    }, () => {
      this.submitButtonText = $localize`Complete`;
      this.formSubmitted = true;
      this.submissionComplete.emit(true);
      this.newCustomer.reset();
    });
  }

  formatPayload(obj: any): object {
    console.log(obj);
    const result = new Customer();
    obj.ip = localStorage.getItem('wf_ip');
    result.visitReason = obj.visitReason.reason;
    result.fname = obj.firstName;
    result.lname = obj.lastName;
    result.weddingDate = obj.weddingDate;
    result.entourage = obj.entourage.toString();
    result.email = obj.email;
    result.postcode = obj.address.postcode;
    result.line1 = obj.address.lineAddress1;
    result.line2 = obj.address.lineAddress2;
    result.line3 = obj.address.lineAddress3;
    result.town = obj.address.town;
    result.county = obj.address.county;
    result.country = obj.address.country;
    result.countryCode = obj.address.countryCode;
    result.telephone = obj.telephone ? obj.telephone.e164Number : '';
    result.sms = obj.marketingPermissions.sms ? 'yes' : 'no';
    result.phone = obj.marketingPermissions.phone ? 'yes' : 'no';
    result.cmail = obj.marketingPermissions.email ? 'yes' : 'no';
    result.dropDownHeardFrom = obj.referralId.toString();
    result.comment = obj.comments;
    result.storeIp = obj.ip;
    return result;
  }

}
