import {Observable, throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import {catchError, finalize, retry, switchMap, tap} from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { TokenService } from './token.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private appConfigService: AppConfigService, private tokenService: TokenService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let count = 0;
    this.appConfigService.show();
    if (req.method === 'OPTIONS') {
      console.log('options');
    }
    if (req.url.includes('https://api.businesscentral.dynamics.com')) { // TODO GET from env file
        count++;
        req = req.clone({
        setHeaders: { Authorization: `Bearer ${this.tokenService.getToken()}` }
      });
    }
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.appConfigService.hide();
        }
      }),
      finalize(() => {
        this.appConfigService.hide();
      }),
      catchError(error => {
        if (error.status === 401 && req.url.includes('https://api.businesscentral.dynamics.com')) { // TODO GET from env file
          return this.reAuthenticate(error, req).pipe(
            tap((event: HttpEvent<any>) => {
              this.appConfigService.show();
              req = req.clone({
                setHeaders: { Authorization: `Bearer ${this.tokenService.getToken()}` }
              });
            }),
            switchMap(() => next.handle(req).pipe(
              tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.appConfigService.hide();
                }
              }),
              retry(1)
            )),
            retry(3)
          );
        }
        this.appConfigService.hide();
        return throwError(error);
      })
    );
  }

  // @ts-ignore
  reAuthenticate(error, req): Observable<any> {
    // Do your auth call here
    // @ts-ignore
    return this.tokenService.fetchToken().pipe(
      tap((data) => {
        this.tokenService.saveToken(data.access_token);
      }),
      // @ts-ignore
      catchError((err) => {
        return throwError(error);
      })
    );
  }
}
