import { Injectable } from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, throwError, TimeoutError} from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJodHRwczovL2FwaS5idXNpbmVzc2NlbnRyYWwuZHluYW1pY3MuY29tIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMjlmNzU3ODktOThmYS00M2EyLTg4MTgtYjY3MDQ2YjNlYThhLyIsImlhdCI6MTY1Nzg3NjE3NSwibmJmIjoxNjU3ODc2MTc1LCJleHAiOjE2NTc4ODAwNzUsImFpbyI6IkUyWmdZSmhUYlozRnIvT3BmTUtDNUcvSFpqdzJCd0E9IiwiYXBwaWQiOiJjYmY2MDFiZi1iNzUzLTRiNmMtYjQ3ZC1mMGFkZDAwM2ZlODciLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8yOWY3NTc4OS05OGZhLTQzYTItODgxOC1iNjcwNDZiM2VhOGEvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiI2MmQyNTAwYS1hYTZhLTRmMWMtODBhNS05NWFlMDgwZmU2ZGQiLCJyaCI6IjAuQVhrQWlWZjNLZnFZb2tPSUdMWndSclBxaWozdmJabHNzMU5CaGdlbV9Ud0J1Sjk1QUFBLiIsInJvbGVzIjpbIkF1dG9tYXRpb24uUmVhZFdyaXRlLkFsbCIsImFwcF9hY2Nlc3MiLCJBUEkuUmVhZFdyaXRlLkFsbCJdLCJzdWIiOiI2MmQyNTAwYS1hYTZhLTRmMWMtODBhNS05NWFlMDgwZmU2ZGQiLCJ0aWQiOiIyOWY3NTc4OS05OGZhLTQzYTItODgxOC1iNjcwNDZiM2VhOGEiLCJ1dGkiOiJTRmJIVlVRZ0YwbVJTR0hPMXRabkFBIiwidmVyIjoiMS4wIn0.ecotIccyREKgHVjFj2nERbL2CB_pNFPwiiIWNwznxhZpD2o9fXavWhYMLyv5V0LjFJQ0M6zONTb1ulbolFnx2vfxpJTFB1IlswJEH_tNzyXowNNs_I8LCyywp2vK-aF9TcaW7lViVJOxag7ufMKaTF91xhWcDydK4Qld_4qXJGSThJmXFVna6WJO6iNeZhiGXSO7DxVY1plkMq8gTYA3gcSsGKWBiz3VQdesCp6TQojzxjvMbIySgFQ9xLwZXrvGgTjMzrwV8oQ6FuAADtc69ylbHa3Eflf9CXJU_C2vbC-3P5jGtRHdXiiKq8PSLC3DZTPjGo70Dd5wlfxZ6Q2hZw',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private env = environment;
  private addressLookUp = this.env.api.address_look_up;
  private bc = this.env.api.bc;
  public objData: object | undefined;
  visibility: BehaviorSubject<boolean>;
  constructor(private http: HttpClient) {
    this.visibility = new BehaviorSubject<boolean>(false);
  }
  // tslint:disable-next-line:typedef
  getCountryList() {
    return this.http.get<any>(`${this.addressLookUp.url}countries?key=${this.addressLookUp.key}`);
  }

  // tslint:disable-next-line:typedef
  findAddress(country: string, query: string, id?: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${this.addressLookUp.url}find?key=${this.addressLookUp.key}&country=${country}&query=${query}&id=${id}`);
  }

  // tslint:disable-next-line:typedef
  retrieveAddress(country: string, id: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${this.addressLookUp.url}retrieve?key=${this.addressLookUp.key}&country=${country}&id=${id}`);
  }

  getIpAddress(): any {
    return this.http.get<any>(`${this.env.api.local.base_url}getIpAddress`);
  }

  getStore(ipAddress: string): Observable<any> {
    // Get stores will always use the GB instance as default
    return this.http.get<any>(`${this.bc.endpoint.default_base_url + `companies(${this.bc.endpoint.default_company_id})` + this.bc.endpoint.service.get_store_by_ip_address}'${ipAddress}'`);
  }

  // tslint:disable-next-line:typedef
  setObjData(obj: any) {
    this.objData =  obj;
  }

  // tslint:disable-next-line:typedef
  getObjData() {
    return this.objData;
  }

  show(): void {
    this.visibility.next(true);
  }

  hide(): void {
    this.visibility.next(false);
  }

  createUpdateCustomer(customer: object): Observable<any> {
    return this.http.post(`${this.bc.endpoint.base_url}`, customer, httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        }),
        // @ts-ignore
        catchError((error: any) => {
          console.warn('INT ERR:');
          return throwError(error);
        })
      );
  }

}
