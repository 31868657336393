import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../environments/environment';
import {catchError, tap} from 'rxjs/operators';

const ACCESS_TOKEN = 'wf_access_token';
const headers = new HttpHeaders()
  .set('Pragma', 'no-cache')
  .set('cache-control', 'no-cache');

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }
  getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  saveToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  fetchToken(): Observable<any> {
    return this.http.get<any>(environment.api.local.base_url + 'get-token', { headers })
      .pipe(
        tap(res => {
          if (!res) {
            console.log('is null');
            res.error = 'unknown error';
          }
        }),
        catchError((error: any) => {
          console.warn('INT ERR:');
          return throwError(error);
        })
      );
  }
}
