// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    bc: {
      endpoint: {
        base_url : 'https://api.businesscentral.dynamics.com/v2.0/29f75789-98fa-43a2-8818-b67046b3ea8a/GB_Sandbox/api/kpmg/wed2b/v2.0/',
        default_base_url: 'https://api.businesscentral.dynamics.com/v2.0/29f75789-98fa-43a2-8818-b67046b3ea8a/GB_Sandbox/api/kpmg/wed2b/v2.0/',
        default_company_id: 'd521f791-3edd-ed11-a98a-bdc64eb38e77',
        company_id: 'd521f791-3edd-ed11-a98a-bdc64eb38e77',
        service: {
          create_update_customer: '/welcomeScreens',
          get_store_by_ip_address: '/stores?$filter=storeIPAddress eq '
        }
      }
    },
    address_look_up: {
      url: 'https://api.craftyclicks.co.uk/address/1.1/',
      key: '3bafd-b8774-10898-02b28'
    },
    local: {
      base_url: 'https://staging.wf.wed2b.com/api/'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
