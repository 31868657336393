import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { timer, Subscription } from 'rxjs';


@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.less']
})

export class ThankYouComponent implements OnInit {
  @Output() submissionComplete = new EventEmitter();
  countDown: Subscription | undefined;
  counter = 10;
  tick = 1000;
  isChecked = false;
  constructor() { }

  ngOnInit(): void {
    this.countDown = timer(0, this.tick)
      .subscribe(() => {
        this.isChecked = true;
        --this.counter;
        if (this.counter === 0) {
          this.submissionComplete.emit(false);
          window.location.reload();
        }
      });
  }

  // tslint:disable-next-line:typedef use-lifecycle-interface
  ngOnDestroy(){
    // @ts-ignore
    this.countDown.unsubscribe();
  }
}
