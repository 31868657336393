<h2 i18n>Welcome Back</h2>
<form id="existing-customer-form" [formGroup]="existingCustomer" (ngSubmit)="onSubmit()">
  <div class="email-wrapper">
    <mat-form-field appearance="outline">
      <mat-label i18n>Email</mat-label>
      <input matInput id="email" name="email" type="email"  [email]="true" formControlName="email" autocomplete="off" required placeholder="Email">
    </mat-form-field>
  </div>
  <div id="visit-reason-group" formGroupName="visitReason">
    <div class="visit-reason-label"><span i18n>What have you come to look for today?</span><span class="required-field" [ngClass]="{'input-invalid': existingCustomer.controls.visitReason.invalid, 'input-valid': existingCustomer.controls.visitReason.valid}">*</span></div>
    <mat-radio-group formControlName="reason" aria-label="Select an option">
      <mat-radio-button id="wedding-dresses" class="mat-radio-button" value="wedding-dresses" ><span i18n>Wedding Dresses</span></mat-radio-button>
      <mat-radio-button id="bridesmaids-dresses" class="mat-radio-button" value="bridesmaid-dresses" ><span i18n>Bridesmaids/Flower Girl Dresses</span></mat-radio-button>
      <mat-radio-button id="accessories" class="mat-radio-button" value="accessories" ><span i18n>Accessories</span></mat-radio-button>
      <mat-radio-button id="wedding-dress-collection" class="mat-radio-button" value="wedding-dress-collection" ><span i18n>Wedding Dress Click and Collect Order</span></mat-radio-button>
      <mat-radio-button id="holy-communion" class="mat-radio-button" value="holy-communion" ><span i18n>Holy Communion Dresses</span></mat-radio-button>
      <mat-radio-button id="exploratory-visit" class="mat-radio-button" value="exploratory-visit" ><span i18n>Exploratory Visit</span></mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="entourage-wrapper">
    <mat-form-field appearance="outline">
      <mat-label i18n>How many in your entourage today, including yourself?</mat-label>
      <input matInput id="number" name="number" type="number" min="1" max="10" formControlName="entourage" autocomplete="off" readonly required placeholder="Entourage">
    </mat-form-field>
    <div class="btn-increment-decrement-wrapper">
      <span (click)="increment()" class="material-icons">add</span>
      <span (click)="decrement()" class="material-icons">remove</span>
    </div>
  </div>
  <div>
    <button id="submit" class="button" type="submit" [disabled]="!existingCustomer.valid">{{submitButtonText}}</button>
  </div>
  <div [hidden]="!error" class="submission-error">
    <p>
      <mat-icon aria-hidden="false" class="warning" aria-label="warning icon">warning</mat-icon>
      <samp id="errorMessage">{{errorMessage}}</samp>
    </p>
  </div>
</form>


