import {Component, Inject, isDevMode, LOCALE_ID} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {environment} from '../environments/environment';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {TokenService} from './token.service';
import { ActivatedRoute } from '@angular/router';

export type ExistingType = 'yes' | 'no' | null;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'welcome-form';
  submissionComplete: boolean | false | undefined;
  existing: string | null = null;
  showBack = false;
  yesNo = null;
  locale: string;
  ip: string | undefined;
  env = environment;
  ipSuccess = false;
  storeName: string | undefined | null;
  year: number;
  languages: any = [
    {name: 'English', locale: 'en-GB', href: '/', image: 'gb.svg'},
    {name: 'Dutch', locale: 'nl', href: '/nl/', image: 'nl.svg'},
    {name: 'Deutsch', locale: 'de', href: '/de/', image: 'de.svg'},
    {name: 'Français', locale: 'fr', href: '/fr/', image: 'fr.svg'}
  ];
  constructor(@Inject(LOCALE_ID) protected localeId: string,
              private srv: AppConfigService,
              private snackBar: MatSnackBar,
              private tokenService: TokenService,
              private route: ActivatedRoute) {
    // @ts-ignore
    this.locale = this.localeId;
    this.year = new Date().getFullYear();
    if (isDevMode()) {
      console.log('👋 BC Development!');
    } else {
      console.log('💪 BC Production!');
    }
    this.route.queryParams
      .subscribe(params => {
          this.ip = params.ip;
        }
      );
    const token = this.tokenService.getToken();
    if (!token) {
      this.tokenService.fetchToken().subscribe((data) => {
        if (data.error) {
          this.openSnackBar(data.error_description, 'DISMISS').afterDismissed().subscribe(() => {
            // window.location.reload();
          });
        } else {
          this.tokenService.saveToken(data.access_token);
        }
      }, err  => {
        let message: string = 'There has been an error: ' + err.status;
        let reload: boolean = true;
        if (err.status === 0) {
          message += ', ' + err.statusText;
          reload = false;
        }
        this.openSnackBar(message, 'DISMISS').afterDismissed().subscribe(() => {
          if (reload) {
            // window.location.reload();
          }
        });
      });
    }

    this.srv.getIpAddress().subscribe( (data: any) => {
      if (data) {
        this.ip ? localStorage.setItem('wf_ip', this.ip) : localStorage.setItem('wf_ip', data.ip);
      }
    }, (err: any) => {
      console.log(err.status);
      this.ipSuccess = false;
      let message: string = 'There has been an error: ' + err.status;
      if (err.status === 0) {
        message += ', ' + err.statusText;
      }
      this.openSnackBar(message, 'DISMISS').afterDismissed().subscribe(() => {
        window.location.reload();
      });
    }, () => {
      console.log('get ip address complete');
      this.ipSuccess = true;
      const ipAddress = localStorage.getItem('wf_ip');
      // @ts-ignore
      this.srv.getStore(ipAddress).subscribe((data: any) => {
        if (data.value.length) {
          localStorage.setItem('wf_store_name', data.value[0].name);
          localStorage.setItem('wf_country_code', data.value[0].countryCode);
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          this.env.api.bc.endpoint.base_url = data.value[0].endpoint + `companies(${data.value[0].companyID})` + this.env.api.bc.endpoint.service.create_update_customer;
        } else {
          localStorage.removeItem('wf_store_name');
          localStorage.removeItem('wf_country_code');
          // tslint:disable-next-line:max-line-length
          this.env.api.bc.endpoint.base_url = this.env.api.bc.endpoint.default_base_url + `companies(${this.env.api.bc.endpoint.default_company_id})` + this.env.api.bc.endpoint.service.create_update_customer; // SET Default endpoint and service url if no store associate with IP
        }
      }, (err: any) => {
        console.log(err.status);
        this.ipSuccess = true;
        this.openSnackBar('There has been an error: ' + err.status, 'DISMISS').afterDismissed().subscribe(() => {
          window.location.reload();
        });
      }, () => {
        console.log('get store success');
        this.storeName = localStorage.getItem('wf_store_name') ? localStorage.getItem('wf_store_name') : 'undefined';
      });
    });
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, {panelClass: ['wed2b-snackbar']});
  }

  // tslint:disable-next-line:typedef
  get showExistingCustomer() {
    return this.existing === 'yes';
  }

  // tslint:disable-next-line:typedef
  get showNewCustomer() {
    return this.existing === 'no';
  }

  // tslint:disable-next-line:typedef
  toggleForm(type: ExistingType, showBack: boolean ) {
    if (this.ipSuccess) {
      this.showBack = showBack;
      this.showBack = !this.showBack;
      this.existing = type;
      this.submissionComplete = false;
    }
  }

  // tslint:disable-next-line:typedef
  back(value: string | null) {
    this.existing = value;
    this.yesNo = null;
    this.showBack = false;
  }

  // tslint:disable-next-line:typedef
  completed(value: boolean) {
    this.submissionComplete = value;
    this.existing = null;
    this.yesNo = null;
  }
}
